import React from "react";
import backgroundMentor from "../assets/images/background-mentor.webp";
import kaffekassan from "../assets/images/kaffekassan.webp";
import pathPilot from "../assets/images/pathPilot.webp";
import kaffekassan2 from "../assets/images/kaffekassan2.webp";

const Home = () => {
  const squareStyle =
    "rounded-xl shadow-xl transition-transform hover:scale-105 gap-2";
  return (
    <div
      style={{}}
      className="px-8 pb-8 pt-16 bg-gray-900 min-h-screen m-0 flex items-center content-center"
    >
      <div
        className="grid grid-cols-auto grid-rows-auto w-full gap-4 sm:gap-4 md:gap-4 lg:gap-4 xl:gap-8"
        style={{
          display: "grid",
          justifyContent: "center",
        }}
      >
        {/*Kaffekassan */}
        <div
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-1 row-span-3 
          grid xs:grid-cols-1 md:grid-cols-12  bg-teal-900 text-white overflow-hidden`}
        >
          <div className="md:col-span-9 lg:col-span-7 xl:col-span-5 px-4 py-4">
            <p className="company px-5 py-1">Kaffekassan</p>
            <h2>Mina sidor - sell application to schoolclasses</h2>
            <div className="grid grid-cols-5 md:hidden lg:hidden xl:hidden: 2xl:hidden">
              <p className="col-span-3">
                A web application from scratch with the primary goal of enabling
                schoolchildren to sell products and raise funds for their
                classes. As the only UX-designer, I took charge of the entire
                UX-design process, ensuring an intuitive and user-friendly
                interface. Additionally, I assumed the role of project lead,
                overseeing the development phase in collaboration with a skilled
                developer. Beyond the initial designing, I have been actively
                involved in the ongoing enhancement of the web app. This
                includes designing new features, incorporating user feedback,
                and making design changes to optimize the overall user
                experience.
              </p>
              <img
                className="col-span-2"
                src={kaffekassan2}
                style={{ width: "50%", justifySelf: "center" }}
                alt="Kaffekassan"
              />
            </div>
            <p className="hidden md:block lg:block xl:block">
              A web application from scratch with the primary goal of enabling
              schoolchildren to sell products and raise funds for their classes.
              As the only UX-designer, I took charge of the entire UX-design
              process, ensuring an intuitive and user-friendly interface.
              Additionally, I assumed the role of project lead, overseeing the
              development phase in collaboration with a skilled developer.
              Beyond the initial designing, I have been actively involved in the
              ongoing enhancement of the web app. This includes designing new
              features, incorporating user feedback, and making design changes
              to optimize the overall user experience.
            </p>

            <p className="whiteopacity mb-1">ROLE/SERVICES</p>
            <div className="border-t whiteopacity mb-4"></div>
            <div className="space-between">
              <p className="rolechip px-5 py-1">UX-designer</p>
              <p className="rolechip px-5 py-1">Project lead</p>
            </div>
          </div>
          <div class="xs:hidden sm:hidden md:block lg:block xl:block 2xl:block md:col-span-3 lg:col-span-5 xl:col-span-7 relative overflow-hidden">
            <div
              class="big-circle flex items-center justify-center h-screen overflow-hidden"
              style={{ backgroundColor: "#DA9566" }}
            >
              <img
                style={{ maxWidth: "850px", width: "150%" }}
                src={kaffekassan}
                alt="Kaffekassan"
              />
            </div>
          </div>
        </div>
        {/*PathPilot project */}
        <div
          style={{
            backgroundImage: `url(${backgroundMentor})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
          }}
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-15 row-span-1
          sm:col-start-1 sm:col-span-8 sm:row-start-9 sm:row-span-3
          md:col-start-1 md:col-span-8 md:row-start-9 md:row-span-3
          lg:col-start-1 lg:col-span-8 lg:row-start-9 lg:row-span-4
          xl:col-start-1 xl:col-span-8 xl:row-start-9 xl:row-span-4 
          grid grid-cols-7 text-black`}
        >
          <div className="col-span-7 lg:col-span-2 xl:col-span-2 px-8 py-8">
            <p className="company px-5 py-1">PathPilot</p>
            <h2>Mentor plattform</h2>
            <p>
              PathPilot was a collaborative project that I contributed to
              alongside a team of talented Swedish students, working in sync
              with a skilled Australian marketing team. As a key developer, I
              took charge of both frontend and backend development, ensuring a
              login feature for a personalized user experience. The platform
              connects students with mentors, providing invaluable guidance for
              their career journeys. With a sophisticated matching system,
              students find mentors who align perfectly with their criteria and
              interests. PathPilot offers a wealth of resources, including
              inspiring articles, easy meeting scheduling, and goal-setting
              tools. While PathPilot is still in its development phase, we've
              successfully built the core components, laying the groundwork for
              its future growth. This platform promises to empower students
              worldwide, ushering in a new era of professional success and
              personal growth. I am proud to have been part of this impactful
              project, enabling students to navigate their path to success with
              confidence and support.
            </p>
            <div class="block lg:hidden flex items-center justify-center">
              <img className="pt-8" src={pathPilot} alt="PathPilot" />
            </div>
            <p className="blackopacity mb-1 mt-20">ROLE/SERVICES</p>
            <div className="border-t blackopacity mb-4"></div>
            <div className="space-between">
              <p className="rolechip px-5 py-1">Development-lead</p>
              <p className="rolechip px-5 py-1">Fullstack-developer</p>
              <p className="rolechip px-5 py-1">Product-owner</p>
            </div>
          </div>
          <div class="hidden lg:block lg:col-span-5 xl:col-span-5 flex items-center justify-center">
            <img className="py-20" src={pathPilot} alt="PathPilot" />
          </div>
        </div>
        {/* Add more div elements with appropriate col-span and row-span classes */}
      </div>
    </div>
  );
};

export default Home;
