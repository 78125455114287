import React from "react";
import profilepic from "../assets/images/profilepic.webp";
import interaktionDesign from "../assets/images/id.webp";
import { FaInstagram, FaFacebook, FaLinkedin, FaSkiing } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RiLandscapeFill } from "react-icons/ri";
import { GiHockey } from "react-icons/gi";
import { PiSoccerBallFill } from "react-icons/pi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import backgroundMentor from "../assets/images/background-mentor.webp";
import kaffekassan from "../assets/images/kaffekassan.webp";
import pathPilot from "../assets/images/pathPilot.webp";
import kaffekassan2 from "../assets/images/kaffekassan2.webp";
import natureBackground from "../assets/images/nature-background.webp";
import SkillPaginator from "./SkillPaginator";
import EmailLink from "./EmailLink";
import PhoneNumberLink from "./PhoneNumberLink";
import ResumeDownloadButton from "./ResumeDowloadButton";

const Home = () => {
  const squareStyle =
    "rounded-xl shadow-xl transition-transform hover:scale-105 gap-2";
  return (
    <div
      style={{}}
      className="px-8 pb-8 pt-16 bg-gray-900 min-h-screen m-0 flex items-center content-center"
    >
      <div
        className="grid grid-cols-auto grid-rows-auto w-full gap-4 sm:gap-4 md:gap-4 lg:gap-4 xl:gap-8"
        style={{
          display: "grid",
          justifyContent: "center",
        }}
      >
        {/* About me */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${natureBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className={`${squareStyle} bg-green-900
          col-start-1 col-span-8 row-start-4 row-span-3
          sm:col-start-1 sm:col-span-5 sm:row-start-4 sm:row-span-4
          md:col-start-1 md:col-span-2 md:row-start-1 md:row-span-4
          lg:col-start-1 lg:col-span-2 lg:row-start-1 lg:row-span-4
          xl:col-start-1 xl:col-span-2 xl:row-start-1 xl:row-span-3 px-4 py-4 text-white h-full`}
        >
          <h2>Simon Pedersen</h2>
          <div>
            <p style={{ fontSize: "12px" }} className="whiteopacity mb-1">
              ABOUT ME
            </p>
            <div className="border-t whiteopacity mb-4"></div>
            <p>
              I am a 24 year old student, stuying my fourth year in Master of
              Science, interaction Technology and Design program on Umeå
              University. From age 4 until age of 20 I competed at elite level
              in alpine skiing. From my skiing career I bring focus and
              purposefulness. I know what is required when entering a project.
            </p>
          </div>

          <div>
            <p style={{ fontSize: "12px" }} className="whiteopacity mb-1" STYLE>
              CONTACT
            </p>
            <div className="border-t whiteopacity mb-4"></div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <a href="tel:+46705608877">
                <BsFillTelephoneFill className="text-md text-white mr-2 hover:text-blue-500 hover:scale-120" />
              </a>
              <p>
                <PhoneNumberLink phoneNumber="+46705608877">
                  +4670 560 88 77
                </PhoneNumberLink>
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <a href="mailto:spedersen1999@gmail.com">
                <AiFillMail className="text-md text-white mr-2 hover:text-blue-500 hover:scale-120" />
              </a>
              <p>
                <EmailLink email="spedersen1999@gmail.com" />
              </p>
            </div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/place/Ume%C3%A5/@63.7737781,19.6548725,9z/data=!4m6!3m5!1s0x467c4e1b68add7f9:0x4034506de8c8560!8m2!3d63.8258471!4d20.2630354!16zL20vMDN6bXcx?entry=ttu"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <MdLocationOn className="text-md text-white mr-2 hover:text-blue-500 hover:scale-120 " />
                <p>Umeå, Sweden</p>
              </div>
            </a>
          </div>

          <div>
            <p style={{ fontSize: "12px" }} className="whiteopacity mb-1">
              INTERESTS
            </p>
            <div className="border-t whiteopacity mb-4"></div>
            <div className="flex space-x-8">
              {/* Customizing the icons */}
              <FaSkiing className="text-3xl text-blue-500" />
              <PiSoccerBallFill className="text-3xl text-green-500" />
              <RiLandscapeFill className="text-3xl text-purple-500" />
              <GiHockey className="text-3xl text-red-500" />
            </div>
          </div>
        </div>
        {/* Profile picture */}
        <div
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-1 row-span-2 
          sm:col-start-1 sm:col-span-8 sm:row-start-1 sm:row-span-3
          md:col-start-3 md:col-span-5 md:row-start-1 md:row-span-2
          lg:col-start-3 lg:col-span-5 lg:row-start-1 lg:row-span-2 
          xl:col-start-3 xl:col-span-5 xl:row-start-1 xl:row-span-2 
          bg-green-500 overflow-hidden`}
        >
          <img
            src={profilepic}
            alt="profilepic"
            className=" object-cover"
            style={{ width: "105%", height: "105%" }}
          />
        </div>
        {/*My Skills */}
        <div
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-8 row-span-1
          sm:col-start-6 sm:col-span-3 sm:row-start-6 sm:row-span-2
          md:col-start-6 md:col-span-3 md:row-start-6 md:row-span-2
          lg:col-start-6 lg:col-span-3 lg:row-start-6 lg:row-span-2
          xl:col-start-8 xl:col-span-1 xl:row-start-1 xl:row-span-2 bg-white`}
        >
          {/* <div className="flex flex-col items-center justify-center w-full h-full">
            <h1>My Skills</h1>
            <div className="grid grid-cols-3 grid-rows-2 xl:grid-cols-2 xl:grid-rows-3 gap-2 lg:gap-5 xl:gap-8">
              <div className="flex items-center justify-center">
                <a href="https://www.javascript.com">
                  <img src={javascript} alt="JavaScript" />
                </a>
              </div>
              <div className="flex items-center justify-center">
                <a href="https://www.typescript.com">
                  <img src={typescript} alt="TypeScript" />
                </a>
              </div>
              <div className="flex items-center justify-center">
                <a href="https://www.html.com">
                  <img src={html} alt="HTML" />
                </a>
              </div>
              <div className="flex items-center justify-center">
                <a href="https://www.css.com">
                  <img src={css} alt="CSS" />
                </a>
              </div>
              <div className="flex items-center justify-center">
                <a href="https://www.react.com">
                  <img src={react} alt="React" />
                </a>
              </div>
              <div className="flex items-center justify-center">
                <a href="https://www.figma.com">
                  <img src={figma} alt="Figma" />
                </a>
              </div>
            </div>
          </div> */}
          <SkillPaginator />
        </div>
        {/*Social links */}
        <div
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-3 row-span-1
          h-16
          sm:col-start-6 sm:col-span-3 sm:row-start-4 sm:row-span-1 sm:h-auto
          md:col-start-8 md:col-span-1 md:row-start-1 md:row-span-2 md:h-auto
          lg:col-start-8 lg:col-span-1 lg:row-start-1 lg:row-span-2 lg:h-auto
          xl:col-start-3 xl:col-span-1 xl:row-start-3 xl:row-span-1 xl:h-auto bg-gray-200
        `}
        >
          <div className="flex items-center justify-center w-full h-full  ">
            <div className="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-8 lg:gap-5 xl:gap-8 xl:p-6">
              <div className="flex items-center justify-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/simonpedersen"
                >
                  <FaInstagram className="text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl hover:text-blue-500" />
                </a>
              </div>
              <div className="flex items-center justify-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/simon.pedersen.568/"
                >
                  <FaFacebook className="text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl hover:text-blue-500" />
                </a>
              </div>
              <div className="flex items-center justify-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.twitter.com/simonpedersen99"
                >
                  <FaXTwitter className="text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl hover:text-blue-500" />
                </a>
              </div>
              <div className="flex items-center justify-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/simon-pedersen-4734921a6/"
                >
                  <FaLinkedin className="text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl hover:text-blue-500" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*Resume */}
        <div
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-7 row-span-1
          sm:col-start-6 sm:col-span-3 sm:row-start-5 sm:row-span-1
          md:col-start-1 md:col-span-2 md:row-start-5 md:row-span-1
          lg:col-start-1 lg:col-span-2 lg:row-start-5 lg:row-span-1
          xl:col-start-6 xl:col-span-3 xl:row-start-4 xl:row-span-1 bg-gray-200
        `}
        >
          <ResumeDownloadButton />
        </div>
        {/*Kaffekassan */}
        <div
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-9 row-span-3
          sm:col-start-1 sm:col-span-8 sm:row-start-8 sm:row-span-3
          md:col-start-3 md:col-span-6 md:row-start-3 md:row-span-3
          lg:col-start-3 lg:col-span-6 lg:row-start-3 lg:row-span-3
          xl:col-start-4 xl:col-span-5 xl:row-start-3 xl:row-span-3 
          grid xs:grid-cols-1 md:grid-cols-12  bg-teal-900 text-white overflow-hidden`}
        >
          <div className="md:col-span-9 lg:col-span-7 xl:col-span-5 px-4 py-4">
            <p className="company px-5 py-1">Kaffekassan</p>
            <h2>Mina sidor - sell application to schoolclasses</h2>
            <div className="grid grid-cols-5 md:hidden lg:hidden xl:hidden: 2xl:hidden">
              <p className="col-span-3">
                A web application from scratch with the primary goal of enabling
                schoolchildren to sell products and raise funds for their
                classes. As the only UX-designer, I took charge of the entire
                UX-design process, ensuring an intuitive and user-friendly
                interface. Additionally, I assumed the role of project lead,
                overseeing the development phase in collaboration with a skilled
                developer. Beyond the initial designing, I have been actively
                involved in the ongoing enhancement of the web app. This
                includes designing new features, incorporating user feedback,
                and making design changes to optimize the overall user
                experience.
              </p>
              <img
                className="col-span-2"
                src={kaffekassan2}
                style={{ width: "50%", justifySelf: "center" }}
                alt="Kaffekassan"
              />
            </div>
            <p className="hidden md:block lg:block xl:block">
              A web application from scratch with the primary goal of enabling
              schoolchildren to sell products and raise funds for their classes.
              As the only UX-designer, I took charge of the entire UX-design
              process, ensuring an intuitive and user-friendly interface.
              Additionally, I assumed the role of project lead, overseeing the
              development phase in collaboration with a skilled developer.
              Beyond the initial designing, I have been actively involved in the
              ongoing enhancement of the web app. This includes designing new
              features, incorporating user feedback, and making design changes
              to optimize the overall user experience.
            </p>

            <p className="whiteopacity mb-1">ROLE/SERVICES</p>
            <div className="border-t whiteopacity mb-4"></div>
            <div className="space-between">
              <p className="rolechip px-5 py-1">UX-designer</p>
              <p className="rolechip px-5 py-1">Project lead</p>
            </div>
          </div>
          <div class="xs:hidden sm:hidden md:block lg:block xl:block 2xl:block md:col-span-3 lg:col-span-5 xl:col-span-7 relative overflow-hidden">
            <div
              class="big-circle flex items-center justify-center h-screen overflow-hidden"
              style={{ backgroundColor: "#DA9566" }}
            >
              <img
                style={{ maxWidth: "850px", width: "150%" }}
                src={kaffekassan}
                alt="Kaffekassan"
              />
            </div>
          </div>
        </div>
        {/*Education */}
        <div
          style={{ backgroundColor: "#C09696" }}
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-12 row-span-1
          sm:col-start-1 sm:col-span-8 sm:row-start-9 sm:row-span-3
          md:col-start-1 md:col-span-5 md:row-start-6 md:row-span-2
          lg:col-start-1 lg:col-span-5 lg:row-start-6 lg:row-span-2
          xl:col-start-1 xl:col-span-3 xl:row-start-4 xl:row-span-2
          grid grid-cols-12 overflow-hidden`}
        >
          <div class="col-span-4 sm:col-span-4 md:col-span-7 lg:col-span-7 xl:col-span-5 relative overflow-hidden">
            <div
              class="big-circle-left flex items-center justify-center h-screen overflow-hidden"
              style={{ backgroundColor: "white" }}
            >
              <img
                style={{ width: "50%" }}
                src={interaktionDesign}
                alt="Interaction & Design"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center col-span-8 sm:col-span-8 md:col-span-5 lg:col-span-5 xl:col-span-7 px-4 py-4">
            <h2>My education</h2>
            <p>
              I am currently enrolled in the Master of Science program in
              Interaction Technology and Design at Umeå University, which I am
              scheduled to complete in 2024. This rigorous and comprehensive
              program focuses on the intersection of technology, design, and
              human-computer interaction.
            </p>
          </div>
        </div>
        {/*PathPilot project */}
        <div
          style={{
            backgroundImage: `url(${backgroundMentor})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
          }}
          className={`${squareStyle} 
          col-start-1 col-span-8 row-start-13 row-span-3
          sm:col-start-1 sm:col-span-8 sm:row-start-9 sm:row-span-3
          md:col-start-1 md:col-span-8 md:row-start-9 md:row-span-3
          lg:col-start-1 lg:col-span-8 lg:row-start-9 lg:row-span-4
          xl:col-start-1 xl:col-span-8 xl:row-start-9 xl:row-span-4 
          grid grid-cols-7 text-black`}
        >
          <div className="col-span-7 lg:col-span-2 xl:col-span-2 px-8 py-8">
            <p className="company px-5 py-1">PathPilot</p>
            <h2>Mentor plattform</h2>
            <p>
              PathPilot was a collaborative project that I contributed to
              alongside a team of talented Swedish students, working in sync
              with a skilled Australian marketing team. As a key developer, I
              took charge of both frontend and backend development, ensuring a
              login feature for a personalized user experience. The platform
              connects students with mentors, providing invaluable guidance for
              their career journeys. With a sophisticated matching system,
              students find mentors who align perfectly with their criteria and
              interests. PathPilot offers a wealth of resources, including
              inspiring articles, easy meeting scheduling, and goal-setting
              tools. While PathPilot is still in its development phase, we've
              successfully built the core components, laying the groundwork for
              its future growth. This platform promises to empower students
              worldwide, ushering in a new era of professional success and
              personal growth. I am proud to have been part of this impactful
              project, enabling students to navigate their path to success with
              confidence and support.
            </p>
            <div class="block lg:hidden flex items-center justify-center">
              <img className="pt-8" src={pathPilot} alt="PathPilot" />
            </div>
            <p className="blackopacity mb-1 mt-20">ROLE/SERVICES</p>
            <div className="border-t blackopacity mb-4"></div>
            <div className="space-between">
              <p className="rolechip px-5 py-1">Development-lead</p>
              <p className="rolechip px-5 py-1">Fullstack-developer</p>
              <p className="rolechip px-5 py-1">Product-owner</p>
            </div>
          </div>
          <div class="hidden lg:block lg:col-span-5 xl:col-span-5 flex items-center justify-center">
            <img className="py-20" src={pathPilot} alt="PathPilot" />
          </div>
        </div>
        {/* Add more div elements with appropriate col-span and row-span classes */}
      </div>
    </div>
  );
};

export default Home;
