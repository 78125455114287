import React from "react";
import { FaDownload } from "react-icons/fa";

const ResumeDownloadButton = () => {
  const handleDownload = () => {
    // Replace 'resume.pdf' with the actual name of your resume file
    const resumeUrl = process.env.PUBLIC_URL + "/CV2023.pdf";
    window.open(resumeUrl, "_blank");
  };

  return (
    <button
      className="flex items-center justify-center w-full h-full p-2 rounded-full bg-white text-black border border-black transition-colors hover:bg-black hover:border-white hover:text-white rounded-xl shadow-xl transition-transform hover:scale-105 gap-2"
      onClick={handleDownload}
    >
      <FaDownload className="h-6 w-6" />
      <span className="whitespace-nowrap">Open Resume</span>
    </button>
  );
};

export default ResumeDownloadButton;
