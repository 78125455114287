// Menu.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Menu = () => {
  const [isMenuVisible, setMenuVisibility] = useState(true);
  const [isDropDownVisible, setDropDownVisibility] = useState(false);
  const [isScrollingUp, setScrollingUp] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setScrollingUp(currentScrollY < prevScrollY);
    setMenuVisibility(currentScrollY <= 0 || isScrollingUp || !isMenuVisible); // Updated condition
    setPrevScrollY(currentScrollY);
  };

  useEffect(() => {
    setPrevScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <nav
      className={`sm:bg-gray-900 fixed top-0 left-0 w-full transition-all z-50 ${
        isMenuVisible
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="container mx-auto px-4 py-3">
        <div
          className={`flex items-top ${
            window.scrollY !== 0 ? "justify-end" : "justify-between"
          }`}
        >
          {/* App Name for smaller screens */}
          <div
            className={`flex xs:hidden ${
              window.scrollY !== 0 ? "hidden" : "block"
            }`}
          ></div>
          {/* Hamburger icon for smaller screens */}
          <div
            className={`${
              isDropDownVisible
                ? " rounded-xl grid grid-cols items-center shadow-xl transition-transform hover:scale-105 sm:hidden w-48 h-56 bg-white p-2 mr-4"
                : "sm:hidden w-10 h-10 rounded-full bg-white p-2 mr-4 hover:scale-110 r-0"
            } `}
          >
            <button
              onClick={() => setDropDownVisibility(!isDropDownVisible)}
              className={`text-gray-600 focus:outline-none justify-self-end ${
                isDropDownVisible ? "p-1" : "p-0"
              } `}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
            <div
              className={`${
                isDropDownVisible ? "block" : "hidden"
              } justify-self-center text-center`}
            >
              <a
                href="/"
                className={`block py-3 hover:text-black justify-self-center text-gray-600 ${
                  location.pathname === "/"
                    ? "text-black font-bold"
                    : "text-gray-600"
                }`}
              >
                Home
              </a>
              <a
                href="/about"
                className={`block py-3 hover:text-black justify-self-center text-gray-600 ${
                  location.pathname === "/about"
                    ? "text-black font-bold"
                    : "text-gray-600"
                }`}
              >
                About Me
              </a>
              <a
                href="/projects"
                className={`block py-3 hover:text-black justify-self-center text-gray-600${
                  location.pathname === "/projects"
                    ? "text-black font-bold"
                    : "text-gray-600"
                }`}
              >
                Projects
              </a>
            </div>
            <div></div>
          </div>
          {/* Dropdown for smaller screens/*/}
        </div>
        {/* Hamburger menu items for larer screens */}
        <div
          className={`hidden sm:flex sm:items-center sm:justify-center sm:space-x-4 ${
            isMenuVisible ? "block" : "hidden"
          }`}
        >
          <a
            href="/"
            className={`py-2 px-16 hover:text-white text-gray-300 ${
              location.pathname === "/"
                ? "text-white font-bold"
                : "text-gray-300"
            }`}
          >
            Home
          </a>
          <a
            href="/about"
            className={`py-2 px-16 text-gray-300 hover:text-white ${
              location.pathname === "/about"
                ? "text-white font-bold"
                : "text-gray-300"
            }`}
          >
            About Me
          </a>
          <a
            href="/projects"
            className={`py-2 px-16 hover:text-white text-gray-300 ${
              location.pathname === "/projects"
                ? "text-white font-bold"
                : " text-gray-300"
            }`}
          >
            Projects
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
