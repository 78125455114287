import React from "react";

const PhoneNumberLink = ({ phoneNumber, children }) => {
  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <a href={`tel:${phoneNumber}`} onClick={handlePhoneClick}>
      {children}
    </a>
  );
};

export default PhoneNumberLink;
