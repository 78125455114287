import React from "react";
import openGraphBackground from "../assets/images/openGraphBackground.png";

const OpenGraphImage = () => {
  return (
    <div>
      <img src={openGraphBackground} alt="open Graph imagee" />
    </div>
  );
};

export default OpenGraphImage;
