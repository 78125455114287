import "./App.css";
import Home from "./components/Home";
import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "./components/About";
import Projects from "./components/Projects";
import NotFound from "./components/NotFound";
import OpenGraphImage from "./components/OpenGraphImage";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/openGraphImage" element={<OpenGraphImage />} />
      <Route component={<NotFound />} />
    </Routes>
  );
}

export default App;
