import React, { useState, useEffect } from "react";
import html from "../assets/images/html.webp";
import css from "../assets/images/css.webp";
import javascript from "../assets/images/javascript.webp";
import react from "../assets/images/react.webp";
import typescript from "../assets/images/typescript.webp";
import figma from "../assets/images/figma.webp";
import soccer from "../assets/images/soccer.webp";
import mongodb from "../assets/images/mongodb.webp";
import express from "../assets/images/express.webp";
import python from "../assets/images/python.webp";
import github from "../assets/images/github.webp";
import materialui from "../assets/images/materialui.webp";
import tailwindcss from "../assets/images/tailwindcss.webp";

const skills = [
  { name: "JavaScript", img: javascript },
  { name: "TypeScript", img: typescript },
  { name: "HTML", img: html },
  { name: "CSS", img: css },
  { name: "React", img: react },
  { name: "Figma", img: figma },
  { name: "MongoDB", img: mongodb },
  { name: "Express.js", img: express },
  { name: "Python", img: python },
  { name: "GitHub", img: github },
  { name: "Material UI", img: materialui },
  { name: "Tailwind CSS", img: tailwindcss },
];

const SkillPaginator = () => {
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [isHovering, setIsHovering] = useState(false);
  const totalPages = Math.ceil(skills.length / itemsPerPage);
  let autoSwitchTimer;

  useEffect(() => {
    if (isHovering) {
      // Start or reset the timer when hovering starts
      // eslint-disable-next-line react-hooks/exhaustive-deps
      autoSwitchTimer = setTimeout(() => {
        switchPage((currentPage % totalPages) + 1);
      }, 5000);
    } else {
      // Clear the timer when hovering stops
      clearTimeout(autoSwitchTimer);
    }

    return () => {
      // Clear the timer when the component is unmounted
      clearTimeout(autoSwitchTimer);
    };
  }, [currentPage, totalPages, isHovering]);

  const switchPage = (page) => {
    setCurrentPage(page);

    // Restart automatic switching after switching manually
    if (isHovering) {
      clearTimeout(autoSwitchTimer);
      autoSwitchTimer = setTimeout(() => {
        switchPage((currentPage % totalPages) + 1);
      }, 5000);
    }
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const renderSkills = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, skills.length);

    return skills.slice(startIndex, endIndex).map((skill, index) => (
      <div key={index} className="flex flex-col items-center justify-center">
        <a
          href={`https://www.${skill.name.toLowerCase()}.com`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={skill.img}
            alt={skill.name}
            className="xs:w-auto h-8 w-auto h-8 lg:w-auto lg:h-10 xl:w-auto xl:h-10 max-w-full m-2"
          />
          <p className="text-center">{skill.name}</p>
        </a>
      </div>
    ));
  };

  return (
    <div
      className="flex flex-col items-center justify-center w-full h-full px-8 py-4 overflow-hidden text-white bg-gray-900 rounded-lg shadow-lg"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${soccer})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <h2>My Skills</h2>
      <div
        className="grid 
        grid-cols-4 grid-rows-1
        sm:grid-cols-2 sm:grid-rows-2
 gap-1 lg:gap-1 xl:gap-1"
      >
        {renderSkills()}
      </div>
      <div className="flex mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`w-20 h-2 mx-1 rounded-full ${
              index + 1 === currentPage ? "bg-blue-500" : "bg-gray-400"
            }`}
            onClick={() => switchPage(index + 1)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default SkillPaginator;
