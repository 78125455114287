import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Menu from "./components/Menu";
import ScrollToTopButton from "./components/ScrollToTopButton";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import { useLocation } from "react-router-dom"; // Import useLocation

const ConditionalMenuAndScrollToTop = () => {
  const location = useLocation();

  // Check if the current URL is /openGraphImage
  if (location.pathname === "/openGraphImage") {
    // Render nothing if the URL is /openGraphImage
    return null;
  }

  // Render Menu and ScrollToTopButton for other URLs
  return (
    <div>
      <Menu />
      <ScrollToTopButton />
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConditionalMenuAndScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
